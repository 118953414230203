$(function() {

	var dirRtlFlag = false;
	if ($("html").attr('dir') == 'rtl') {
		dirRtlFlag = true;
	}

	$('#hp-recommend').slick({
		dots: false,
		infinite: true,
		speed: 1000,
		fade: true,
		rtl: dirRtlFlag,
		arrows: true,
		autoplay: true,
		autoplaySpeed: 5000,
		cssEase: 'linear',
		pauseOnHover: false,
	});

});